import React, { useState, useEffect, useCallback } from "react"
import { getPidFromUrl } from "@StarberryUtils"
import { Link } from "gatsby"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Button, Container, Modal } from "react-bootstrap"
import Slider from "react-slick"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ScrollAnimation from "react-animate-on-scroll"
import $ from "jquery"
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import KeyFeature from "../components/PropertyDetails/KeyFeature/KeyFeature"
import Amenities from "../components/PropertyDetails/Amenities/Amenities"
import PropertyContact from "../components/PropertyDetails/PropertyContact/PropertyContact"
import MortgageCalculator from "../components/MortgageSection/MortgageCalculator";
import PropertyValuation from "../components/PropertyDetails/PropertyValuation/PropertyValuation"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform"
import Loader from "../components/loader"
import SimilarProperties from "../components/PropertyDetails/SimilarProperties/SimilarProperty"
import GoogleMapReact from "google-map-react"
import markerImg from "../components/Map/images/map_marker.png"
import BookAViewing from "../components/forms/book-a-viewing"
import { getVideoId, removePlus } from "../components/utils"
import PlayVideo from "../components/Play/PlayVideo"
import SendFriendFormPage from "../components/forms/send-friend-form"
import NotFoundPage from "../pages/404"
import "../components/PropertyDetails/PropertyDetails.scss"
import SEO from "../components/seo"
import _, { capitalize } from "lodash"

const Marker = () => {
  return (
    <div style={{ pointerEvents: "none" }}>
      <img src={markerImg} style={{ pointerEvents: "none" }} />
    </div>
  )
}

const PropertyContactInline = props => {
  const TEAM_DETAILS = gql`
    query teamDetails($email: String!) {
      teams(where: { Email: $email }) {
        id
        Whatsapp_Number
        Name
        Phone
        Email
        Designation
        Area_of_Special
        URL
        Tile_Image {
          url
          alternativeText
        }
      }
    }
  `
  const { loading, error, data } = useQuery(TEAM_DETAILS, {
    variables: { email: props.email },
  })

  if (error || (data && data.teams.length == 0)) {
    return null
  }
  return (
    <li>
      <div className="contact-agent">
        <div className="contact-agent-photo">
          <img
            src={data?.teams[0]?.Tile_Image?.url}
            alt={
              data?.teams[0]?.Name +
              ", " +
              data?.teams[0]?.Designation +
              " - Espace"
            }
          />
        </div>
        <div className="contact-agent-info">
          <span className="contact-agent-name">{data?.teams[0]?.Name}</span>
          <a
            href={`tel:${data?.teams[0]?.Phone}`}
            className="contact-agent-number"
          >
            {data?.teams[0]?.Phone}
          </a>
        </div>
      </div>
    </li>
  )
}

const PROPERTY_DETAILS = gql`
  query PropertyDetails($id: ID!) {
    property(id: $id) {
      id
      title
      slug
      display_address
      search_type
      extra
      status
      price
      price_qualifier
      bedroom
      crm_negotiator_id
      address
      floorarea_type
      floorarea_min
      latitude
      seo
      building
      longitude
      special
      description
      long_description
      accomadation_summary
      virtual_tour
      images
      imagetransforms
      floorplan
      brochure
      service_charge
      off_plan
      publish
    }
    reviewsCount: googleReviewReviewsConnection {
      aggregate {
        count
      }
    }
    fiveCount: googleReviewReviewsConnection(where: { starRating: "FIVE" }) {
      aggregate {
        count
      }
    }
    fourCount: googleReviewReviewsConnection(where: { starRating: "FOUR" }) {
      aggregate {
        count
      }
    }
    threeCount: googleReviewReviewsConnection(where: { starRating: "THREE" }) {
      aggregate {
        count
      }
    }
    twoCount: googleReviewReviewsConnection(where: { starRating: "TWO" }) {
      aggregate {
        count
      }
    }
    oneCount: googleReviewReviewsConnection(where: { starRating: "ONE" }) {
      aggregate {
        count
      }
    }
    siteConfig {
      Google_Review_Share
    }
    calculators(where: { Title_contains: "mortgage" }) {
      Speak_with_Team {
        Email
      }
    }
  }
`

const videoRef = React.createRef(null)

const firstImgRef = React.createRef(null)
const secondImgRef = React.createRef(null)

const PropertyDetailsTemplate = props => {
  // Property details image count
  const [indexed, setIndex] = useState(1)
  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [photoIndex2, setSecondSlideIndex] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [emailModal, setEmailModal] = React.useState(false)
  const [friendEmailModal, setFriendEmailModal] = React.useState(false)
  const [email, setEmail] = React.useState(null)
  const [property_name, setPropertyName] = React.useState(null)
  const [property_url, setPropertyUrl] = React.useState(null)
  const [property_id, setPropertyId] = React.useState(null)
  const [activeKey, setActiveKey] = React.useState("")

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }
  // Property images lightbox

  // Property floorplan images lightbox
  const [photoIndexFloorplan, setPhotoIndexFloorplan] = useState(0)
  const [isOpenFloor, setIsOpenFloor] = useState(false)

  const [video, showVideo] = useState(false)
  const [video360, show360Tour] = useState(false)

  const openPropertyImageFloor = (e, ind) => {
    e.preventDefault()
    setPhotoIndexFloorplan(ind)
    setIsOpenFloor(true)
  }
  // Property floorplan images lightbox

  // Slider
  const settings = {
    dots: false,
    speed: 800,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          autoplay: false,
        },
      },
    ],
  }
  // Slider
  const settings1 = {
    dots: false,
    speed: 800,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          autoplay: false,
        },
      },
    ],
  }
  // Slider
  const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
    variables: { id: getPidFromUrl() },
  })

  // scroll header
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 900 && !scroll) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })

    // property details scroll menu
    var propertySelector = ".property-details-menu-list li a"

    $(propertySelector).on("click", function () {
      $(propertySelector).removeClass("active")
      $(this).addClass("active")
    })

    document.addEventListener("fullscreenchange", fullScreenHandler, false)
    document.addEventListener(
      "webkitfullscreenchange",
      fullScreenHandler,
      false
    )
    document.addEventListener("MSFullscreenChange", fullScreenHandler, false)
    document.addEventListener("mozfullscreenchange", fullScreenHandler, false)
  }, [])
  // scroll header

  // property details scroll menu
  const propertyAmenities = e => {
    setActiveKey("amenities")
    $("html, body").animate(
      {
        scrollTop:
          $("#property-amenities").offset().top -
          $(".property-details-menu-wrapper").height(),
      },
      100
    )
  }

  const propertyDescription = e => {
    setActiveKey("description")
    $("html, body").animate(
      {
        scrollTop:
          $("#property-description").offset().top -
          $(".property-details-menu-wrapper").height(),
      },
      100
    )
  }

  const propertyLocation = e => {
    setActiveKey("location")
    $("html, body").animate(
      {
        scrollTop:
          $("#property-details-map").offset().top -
          $(".property-details-menu-wrapper").height(),
      },
      100
    )
  }
  // property details scroll menu

  // Social share
  const location = useLocation()

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const [Shareicons, setShareicons] = React.useState(false)

  const openShareicons = () => {
    setShareicons(true)
    if (Shareicons === true) {
      setShareicons(false)
    }
  }

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false)

  const openBookViewformModal = () => {
    setBookViewingformOpen(true)
  }

  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  // Social share

  let processedImgs = JSON.stringify({})
  var propertyLightImages = []
  var propertyLightImagesFloorplan = []
  var reviews = null

  if (data && data.property) {
    // ggfx
    if (data.property?.imagetransforms?.images_Transforms) {
      processedImgs = data.property.imagetransforms.images_Transforms
    }
    // Property details images lightbox
    const propertyImages = data.property.images
    propertyLightImages =
      propertyImages &&
      propertyImages.filter(c => c.url != undefined).map(img => img.srcUrl)
    // Property details images lightbox

    // Property details floorplan images lightbox
    const floorplanImages = data.property.floorplan
    propertyLightImagesFloorplan =
      floorplanImages && floorplanImages.map(img => img.srcUrl)
  }
  if (data && data.reviewsCount) {
    const {
      reviewsCount,
      fiveCount,
      fourCount,
      threeCount,
      twoCount,
      oneCount,
    } = data
    let averageCount =
      (fiveCount.aggregate.count * 5 +
        fourCount.aggregate.count * 4 +
        threeCount.aggregate.count * 3 +
        twoCount.aggregate.count * 2 +
        oneCount.aggregate.count) /
      reviewsCount.aggregate.count
    reviews = {
      totalCount: reviewsCount.aggregate.count,
      average: parseFloat(averageCount.toFixed(2)).toFixed(1),
      link: data.siteConfig.Google_Review_Share,
    }
  }

  const playVideo = () => {
    var elem = videoRef.current
    elem.classList.add("fullscreen-video")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formClick",
      formType: "form-video",
      formId: "local-living-video",
      formName: "Local Living Video",
      formLabel: window.location.href,
    })
  }

  const priceQualifier = (Qualifier, price) => {
    if (Qualifier === "Yes") {
      return "Ask for price ";
    }
    return `AED ${price.toLocaleString()} `;
  }

  function fullScreenHandler() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    if (fullscreenElement == null) {
      showVideo(false)
    }
  }

  if (data && data.property) {
    if (data?.property.publish == false) {
      return <NotFoundPage />
    }
  }

  

  return (
    <React.Fragment>
      {
        !loading && 
            <SEO
              title={data?.property?.seo?.page_title?.value ?  data?.property?.seo?.page_title?.value : `${
                data?.property?.bedroom != 0
                  ? data?.property?.bedroom + " bedroom "
                  : ""
              } ${data?.property?.building} ${data?.property?.status} in ${
                data?.property?.display_address
              }`}
              description={`Find the details of ${data?.property?.bedroom} bedroom ${data?.property?.building} ${data?.property?.status} in ${data?.property?.display_address} with Espace real estate at AED ${data?.property?.price}. Contact our agent to get assistance in knowing more about this ${data?.property?.building}.`}
              image={data?.property?.images?.[0]?.url}
              location={props.location}
            />
      }
     
      <div className="wrapper wrapper-static">
        <Header />
        {loading && (
          <div className="mt-5 pt-5 h-100">
            {" "}
            <Loader />
          </div>
        )}
        {!loading && data.property && (
          <div className="search-list-block property-detail-block">
            <div className="search-item-box">
              <div className="d-xl-flex justify-content-between">
                <div className="search-property-img">
                  <Slider
                    className="card-slider d-block"
                    {...settings}
                    ref={firstImgRef}
                    beforeChange={(currentSlide, nextSlide) => {
                      setPhotoIndex(nextSlide)
                    }}
                  >
                    {data.property.images &&
                      data.property.images
                        .filter(c => c.url != undefined)
                        .map((node, i) => {
                          let processedImages = JSON.stringify({})
                          if (
                            data?.property.imagetransforms?.images_Transforms
                          ) {
                            processedImages =
                              data.property.imagetransforms.images_Transforms
                          }
                          return (
                            <div className="slide-item img-zoom" key={i}>
                              <ImageTransform
                                imagesources={node.url}
                                renderer="srcSet"
                                imagename="property.images.detail"
                                attr={{
                                  alt:
                                    data?.property?.display_address +
                                    " - Espace",
                                  className: "",
                                }}
                                imagetransformresult={processedImages}
                                id={data.property.id}
                              />
                            </div>
                          )
                        })}
                  </Slider>
                  <div className="card-slider">
                    <button
                      type="button"
                      className="slick-arrow slick-prev d-block"
                      onClick={() => {
                        secondImgRef.current.slickPrev()
                        firstImgRef.current.slickPrev()
                      }}
                    >
                      {" "}
                      Previous
                    </button>
                    <button
                      type="button"
                      className="slick-arrow slick-next d-block"
                      onClick={() => {
                        secondImgRef.current.slickNext()
                        firstImgRef.current.slickNext()
                      }}
                    >
                      {" "}
                      Next
                    </button>
                  </div>

                  {/* Property image lightbox */}
                  {isOpen && (
                    <Lightbox
                      mainSrc={propertyLightImages[photoIndex]}
                      nextSrc={
                        propertyLightImages[
                          (photoIndex + 1) % propertyLightImages.length
                        ]
                      }
                      prevSrc={
                        propertyLightImages[
                          (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex(
                          (photoIndex + 1) % propertyLightImages.length
                        )
                      }
                    />
                  )}
                  {/* Property image lightbox */}
                  <div className="property-btn-container">
                    <ul className="property-btn-container-list">
                      <li>
                        {data.property.images && (
                          <div className="property-btns d-flex flex-row">
                            <Button
                              type="button"
                              variant="link"
                              className="photos_box"
                              onClick={e => openPropertyImage(e, 0)}
                            >
                              <i className="icon-camera"></i> {photoIndex + 1}/
                              {
                                data.property.images.filter(
                                  c => c.url != undefined
                                ).length
                              }
                            </Button>
                          </div>
                        )}
                      </li>
                      <li>
                        {data.property.virtual_tour && (
                          <div className="property-btns d-flex flex-row">
                            <Button
                              type="button"
                              variant="link"
                              className="photos_box"
                              onClick={() => {
                                if (
                                  !data.property.virtual_tour.includes("360")
                                ) {
                                  showVideo(true)
                                  playVideo()
                                } else {
                                  show360Tour(true)
                                }
                              }}
                            >
                              <i className="icon-videocam"></i>{" "}
                              <span className="d-none d-md-inline-block text-uppercase">
                                {data.property.virtual_tour.includes("360")
                                  ? "360 tour"
                                  : "360 tour"}
                              </span>
                            </Button>
                          </div>
                        )}
                      </li>
                      <li>
                        {data.property.floorplan?.length > 0 && (
                          <div className="property-btns d-flex flex-row">
                            <Button
                              type="button"
                              variant="link"
                              className="photos_box"
                              onClick={e => openPropertyImageFloor(e, 0)}
                            >
                              <i className="icon-floorplan"></i>{" "}
                              <span className="d-none d-md-inline-block text-uppercase">
                                floorplan
                              </span>
                            </Button>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                {data.property.images && data.property.images.length > 0 && (
                  <div className="d-none d-xl-block side-banner">
                    <Slider
                      className="card-slider d-block"
                      {...settings1}
                      ref={secondImgRef}
                      initialSlide={1}
                      beforeChange={(v, n) => setSecondSlideIndex(n)}
                    >
                      {data.property.images &&
                        data.property.images
                          .filter(c => c.url != undefined)
                          .map((node, i) => {
                            let processedImages = JSON.stringify({})
                            if (
                              data?.property.imagetransforms?.images_Transforms
                            ) {
                              processedImages =
                                data.property.imagetransforms.images_Transforms
                            }
                            return (
                              <div className="slide-item img-zoom" key={i}>
                                <ImageTransform
                                  imagesources={node.url}
                                  renderer="srcSet"
                                  imagename="property.images.propertyDetails_1"
                                  attr={{
                                    alt:
                                      data?.property?.display_address +
                                      " - Espace",
                                    className: "",
                                  }}
                                  imagetransformresult={processedImages}
                                  id={data.property.id}
                                />
                              </div>
                            )
                          })}
                    </Slider>
                  </div>
                )}
              </div>

              {/* Property floorplan image lightbox */}
              {isOpenFloor && (
                <Lightbox
                  mainSrc={propertyLightImagesFloorplan[photoIndexFloorplan]}
                  nextSrc={
                    propertyLightImagesFloorplan[
                      (photoIndexFloorplan + 1) %
                        propertyLightImagesFloorplan.length
                    ]
                  }
                  prevSrc={
                    propertyLightImagesFloorplan[
                      (photoIndexFloorplan +
                        propertyLightImagesFloorplan.length -
                        1) %
                        propertyLightImagesFloorplan.length
                    ]
                  }
                  onCloseRequest={() => setIsOpenFloor(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndexFloorplan(
                      (photoIndexFloorplan +
                        propertyLightImagesFloorplan.length -
                        1) %
                        propertyLightImagesFloorplan.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndexFloorplan(
                      (photoIndexFloorplan + 1) %
                        propertyLightImagesFloorplan.length
                    )
                  }
                />
              )}
              {/* Property floorplan image lightbox */}

              <Container>
                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                  <div className="search-property-details">
                    <div className="d-xl-flex justify-content-between align-items-center">
                      <div className="d-flex flex-row align-items-start">
                        <h1 className="title-txt">
                          {data.property.seo.h1.value ?
                            data.property.seo.h1.value : data.property.display_address}
                          {(data.property.status == "sold" ||
                            data.property.status == "let") && (
                            <a className="btn subscribe-btn excl-btn no-link status-btn position-relative">
                              <span className="d-md-inline-block">
                                {data.property.status}
                              </span>
                            </a>
                          )}
                        </h1>
                        {/* <div className="pt-3"> */}
                        {/* </div> */}
                      </div>
                      <ul className="cards-btns d-flex align-items-center">
                        {data.property.status !== "sold" &&
                          data.property.status !== "let" &&
                          data?.property.crm_negotiator_id?.email && (
                            <li>
                              <a
                                onClick={() => {
                                  setPropertyName(data?.property?.title)
                                  setPropertyUrl(
                                    `/property-${
                                      data?.property?.search_type === "sales"
                                        ? "for-sale"
                                        : "for-rent"
                                    }/${data?.property?.slug}-${
                                      data?.property?.id
                                    }`
                                  )
                                  setPropertyId(data?.property?.id)
                                  setEmail(
                                    data?.property?.crm_negotiator_id?.email
                                  )
                                  setEmailModal(true)
                                }}
                                className="btn email-btn subscribe-btn media-btn"
                              >
                                <i className="icon-mail"></i>
                                <span className="">email</span>
                              </a>
                            </li>
                          )}
                        {/* {data?.property.crm_negotiator_id?.number && (
                          <li>
                            <a
                              href={
                                `tel:` + data.property.crm_negotiator_id.number
                              }
                              className="btn subscribe-btn media-btn"
                            >
                              <i className="icon-mobile"></i>
                              <span className="d-inline-block">call</span>
                            </a>
                          </li>
                        )} */}
                        {data?.property.crm_negotiator_id?.number && (
                          <li>
                            <a
                              href={`https://api.whatsapp.com/send?phone=${removePlus(data?.property?.crm_negotiator_id?.number)}`}
                              target="_blank"
                              className="btn subscribe-btn media-btn whatsapp"
                            >
                              <i className="icon-whatsapp-green"></i>
                              <span className="d-none d-md-inline-block">
                                whatsapp
                              </span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="card-desc">
                      <h2> 
                        {data.property.display_address &&
                            data.property.display_address}
                          {(data.property.status == "sold" ||
                            data.property.status == "let") && (
                            <a className="btn subscribe-btn excl-btn no-link status-btn position-relative">
                              <span className="d-md-inline-block">
                                {data.property.status}
                              </span>
                            </a>
                          )}
                      </h2>
                      <h3>
                        {/* {data?.property?.title} */}
                        {data?.property?.status == "sold"
                        ? "Sold " +
                          _.startCase(data?.property?.title).replace(
                            "For Sale",
                            ""
                          )
                        : data?.property?.status == "let"
                        ? "Let " +
                          _.startCase(data?.property?.title).replace(
                            "To Let",
                            ""
                          )
                        : _.startCase(data?.property?.title)}{" "}
                      
                      </h3>
                    </div>
                    <div className="property-price"></div>
                    <div className="property-overview">
                      <ul className="desc-list d-md-flex flex-wrap">
                        {data.property.price && (
                          <li>
                            <span className="txt-desc">Price:</span>
                            <span className="desc-value price"> 
                                {priceQualifier(data.property.price_qualifier, data.property.price)}  
                            </span>
                          </li>
                        )}
                        {data.property.address && (
                          <li>
                            <span className="txt-desc">Location:</span>
                            <span className="desc-value">
                              {data.property.address.address2 &&
                                data.property.address.address2}
                            </span>
                          </li>
                        )}
                        {data.property.bedroom !== 0 && (
                          <li>
                            <span className="txt-desc">Bedrooms:</span>
                            <span className="desc-value">
                              {" "}
                              {data.property.bedroom && data.property.bedroom}
                            </span>
                          </li>
                        )}
                        {data.property.floorarea_min !== 0 && (
                          <li>
                            <span className="txt-desc">Size:</span>
                            <span className="desc-value">
                              {" "}
                              {data.property.floorarea_min &&
                                data.property.floorarea_min.toLocaleString()}{" "}
                              {data.property.floorarea_type &&
                                data.property.floorarea_type}
                            </span>
                          </li>
                        )}
                        {data.property.crm_negotiator_id && (
                          <PropertyContactInline
                            email={data?.property?.crm_negotiator_id?.email}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </Container>
            </div>

            <div
              className={
                scroll
                  ? "property-details-menu-wrapper active"
                  : "property-details-menu-wrapper"
              }
            >
              <Container>
                <div className="d-flex justify-content-between property-details-wrapper">
                  <ul className="list-inline property-details-menu-list">
                    {data?.property.special?.length > 0 && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className={`propertyAmenities ${
                            activeKey === "amenities" ? "active" : ""
                          }`}
                          onClick={propertyAmenities}
                        >
                          Amenities
                        </a>
                      </li>
                    )}
                    {data?.property.long_description && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className={`propertyDescription ${
                            activeKey === "description" ? "active" : ""
                          }`}
                          onClick={propertyDescription}
                        >
                          Description
                        </a>
                      </li>
                    )}
                    {data?.property.virtual_tour?.length > 0 && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="propertyVideo"
                          onClick={() =>
                            !data.property.virtual_tour.includes("360")
                              ? showVideo(true)
                              : show360Tour(true)
                          }
                        >
                          Video
                        </a>
                      </li>
                    )}
                    {data?.property.latitude && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className={`propertyLocation ${
                            activeKey === "location" ? "active" : ""
                          }`}
                          onClick={propertyLocation}
                        >
                          Location
                        </a>
                      </li>
                    )}
                    {data?.property.floorplan?.length > 0 && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="propertyFloorplan"
                          onClick={e => openPropertyImageFloor(e, 0)}
                        >
                          Floorplan
                        </a>
                      </li>
                    )}
                  </ul>

                  <ul className="cards-btns d-flex align-items-center">
                    {data.property.status !== "sold" &&
                      data.property.status !== "let" && (
                        <li className="share-space social-share-wrapper">
                          <a
                            href="javascript:void(0)"
                            className="d-flex align-items-center share-btn"
                            onClick={openShareicons}
                          >
                            <i className="icon-share"></i> Share
                          </a>
                          {Shareicons && (
                            <div
                              onClick={openShareicons}
                              className="property-share-social-icons2 mobile-details-socila-share"
                            >
                              <FacebookShareButton
                                onClick={() =>
                                  trackerShare("FacebookShareButton")
                                }
                                url={shareurl}
                                className="my-share-button facebook-share"
                              >
                                <FacebookIcon
                                  size={32}
                                  round={false}
                                  borderRadius={`10`}
                                />
                              </FacebookShareButton>
                              <TwitterShareButton
                                onClick={() =>
                                  trackerShare("TwitterShareButton")
                                }
                                url={shareurl}
                                className="my-share-button twitter-share"
                              >
                                <TwitterIcon
                                  size={32}
                                  round={false}
                                  borderRadius={`10`}
                                />
                              </TwitterShareButton>
                              <LinkedinShareButton
                                onClick={() =>
                                  trackerShare("LinkedinShareButton")
                                }
                                url={shareurl}
                                className="my-share-button linkedin-share"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round={false}
                                  borderRadius={`10`}
                                />
                              </LinkedinShareButton>
                              <WhatsappShareButton
                                onClick={() =>
                                  trackerShare("WhatsappShareButton")
                                }
                                url={shareurl}
                                className="my-share-button whatsapp-share"
                              >
                                <WhatsappIcon
                                  size={32}
                                  round={false}
                                  borderRadius={`10`}
                                />
                              </WhatsappShareButton>
                              <EmailShareButton
                                url="#"
                                onClick={() => {
                                  setPropertyName(data?.property?.title)
                                  setPropertyUrl(
                                    `/property-${
                                      data?.property?.search_type === "sales"
                                        ? "for-sale"
                                        : "for-rent"
                                    }/${data?.property?.slug}-${
                                      data?.property?.id
                                    }/`
                                  )
                                  setPropertyId(data?.property?.id)
                                  setFriendEmailModal(true)
                                  trackerShare("SendToFriend")
                                }}
                                className="my-share-button email-share"
                              >
                                <EmailIcon
                                  size={32}
                                  round={false}
                                  borderRadius={`10`}
                                />
                              </EmailShareButton>
                            </div>
                          )}
                        </li>
                      )}

                    {data.property.status !== "sold" &&
                      data.property.status !== "let" && (
                        <li>
                          <a
                            className="btn email-btn subscribe-btn media-btn"
                            onClick={() => {
                              setPropertyName(data?.property?.title)
                              setPropertyUrl(
                                `/property-${
                                  data?.property?.search_type === "sales"
                                    ? "for-sale"
                                    : "for-rent"
                                }/${data?.property?.slug}-${
                                  data?.property?.objectID
                                }`
                              )
                              setPropertyId(data?.property?.id)
                              setEmail(data?.property?.crm_negotiator_id?.email)
                              setEmailModal(true)
                            }}
                          >
                            <i className="icon-mail"></i>
                            <span className="">email</span>
                          </a>
                        </li>
                      )}
                    <li>
                      <a
                        href={`tel:${data?.property?.crm_negotiator_id?.number}`}
                        className="btn subscribe-btn media-btn media-btn-small"
                      >
                        <i className="icon-mobile"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://api.whatsapp.com/send?phone=${removePlus(data?.property?.crm_negotiator_id?.number)}`}
                        className="btn subscribe-btn media-btn media-btn-small"
                      >
                        <i className="icon-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Container>
            </div>

            <KeyFeature propertyData={data.property} />
            <Amenities propertyData={data.property} />
            <PropertyContact email={data?.property?.crm_negotiator_id?.email} />

            <div
              className="property-details-landing-map area-guide-map mb-5"
              id="property-details-map"
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_KEY,
                }}
                // bootstrapURLKeys={{ key: "" }}
                options={{
                  clickableIcons: false,
                }}
                defaultCenter={{
                  lat: 25.13797501888234,
                  lng: 55.12747016889561,
                }}
                defaultZoom={11}
              >
                <Marker
                  show={false}
                  lat={data?.property?.latitude}
                  lng={data?.property?.longitude}
                />
              </GoogleMapReact>
            </div>

            <div className="calculate-valuation">
              <Container>
                <div className="property-form d-xl-flex justify-content-between">
                  <MortgageCalculator
                    property={data?.property}
                    email={data?.calculators?.[0]?.Speak_with_Team?.[0]?.Email}
                  />
                  <PropertyValuation reviews={reviews} />
                </div>
              </Container>
            </div>
            <SimilarProperties
              property={data.property}
              off_plan={data.property?.off_plan === "Yes"}
            />
          </div>
        )}
        <div className="meet-team-footer">
          <Footer
            popular_search="results"
            searchtype={data?.property?.search_type}
            searchBedroomfield={data?.property?.bedroom}
            searchPtype={data?.property?.building}
            Searcharea={data?.property}
            popularSearchForResults={false}
            popularSearch={false}
            popularSearchDetails={true}
            price={data?.property?.price}
          />
        </div>
      </div>
      <Modal
        size="lg"
        show={emailModal}
        onHide={() => setEmailModal(false)}
        centered
        className="contact-form-sec"
      >
        <div className="contact-form subsribe-form">
          <Modal.Header closeButton>
            <Modal.Title as="h2">Book a Viewing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookAViewing
              property_name={property_name}
              property_url={process.env.GATSBY_SITE_URL + property_url}
              property_id={property_id}
              email={email}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="lg"
        show={friendEmailModal}
        onHide={() => setFriendEmailModal(false)}
        centered
        className="contact-form-sec"
      >
        <div className="contact-form subsribe-form">
          <Modal.Header closeButton>
            <Modal.Title as="h2">Send to Friend</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SendFriendFormPage
              property_title={property_name}
              property_url={process.env.GATSBY_SITE_URL + property_url}
              page_url={process.env.GATSBY_SITE_URL + property_url}
              property_img={data?.property?.images[0].url}
              property_id={property_id}
              email={email}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="xl"
        show={video360}
        onHide={() => show360Tour(false)}
        centered
        className="w-100 modal360"
      >
        <Modal.Body>
          {data?.property?.virtual_tour && (
            <iframe
              style={{ height: "90vh", width: "100%" }}
              src={data?.property?.virtual_tour}
            />
          )}
        </Modal.Body>
        <button className="close-btn" onClick={() => show360Tour(false)}>
          <i className="icon-close w-100 h-100" />
        </button>
      </Modal>
      <div ref={videoRef}>
        {data && data.property && data.property.virtual_tour && (
          <PlayVideo
            isOpen={video}
            isCloseFunction={() => showVideo(false)}
            videoId={getVideoId(data?.property?.virtual_tour)}
            isAutoPlay={1}
          />
        )}
        <strong
          className="play-btn close-icon"
          onClick={() => {
            showVideo(false)
            videoRef.current.classList.remove("fullscreen-video")
          }}
        >
          <i className="icon-close" />
        </strong>
      </div>
    </React.Fragment>
  )
}

export default React.memo(PropertyDetailsTemplate)
