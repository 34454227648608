import React, { useState, useEffect } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby"
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

// markup
const NotFoundPage = () => {
    // const [showMenu, setShowMenu] = useState(false);
    // const [render, setRender] = useState(false);
    // const handlerClick = () => {
    //     setShowMenu(!showMenu)
    //     var root = document.getElementsByTagName('html')[0];
    //     if (showMenu == true) {
    //         document.body.className = '';
    //         root.className = '';
    //     } else {
    //         document.body.classList.add("overflow-hidden");
    //         root.classList.add("overflow-hidden");
    //     }
    // }
    // const [showSearch, setShowSearch] = useState(false);
    // const handlerSearchClick = () => {
    //     setShowSearch(!showSearch)
    //     setShowMenu(false)
    //     if (showSearch == true) {
    //         document.body.className = '';
    //     } else {
    //         document.body.classList.add("overflow-hidden");
    //     }
    // }
    // useEffect(() => {
    //     setRender(true)
    // })

    return (
        <div className="page-not-found">
            <>
                <Header />
                    <div className="static-detail-sec">
                        <div className="notfound-page">
                            <Container>
                                <Row>
                                    <Col lg="2" ></Col>
                                    <Col lg="8" >
                                            <div className="notfound-content">
                                                <h1>We’re sorry, the page you were looking for cannot be found.</h1>
                                                <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
                                            </div>
                                            <div className="notfound-btns">
                                                <Link to={`/properties-for-sale/in-dubai/`} className="btn subscribe-btn">Property Search</Link>
                                                <Link to={`/sell-dubai-property/property-valuation/`} className="btn subscribe-btn btn-red">Value Your Property</Link>
                                            </div>
                                    </Col>
                                    <Col lg="2" ></Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                <Footer 
                    popularSearch="Popular_Static"
                />
            </>
        </div>
    )
}

export default NotFoundPage
